import React from "react"
// import { Link } from "gatsby"

import SEO from "../components/seo"
import { useTranslation } from "react-i18next"
// import { Container } from 'react-bootstrap';
import LayoutTitle from "../components/Layouts/LayoutTitle/LayoutTitle"
import { Alert } from "react-bootstrap"


const IndexPage = (props) => {
  const { t } = useTranslation()

  return (
    <div>
      <SEO title="How to solve a Rubik's cube   Как собрать кубик Рубика" />
      <LayoutTitle><h1 >{t('more-lessons.h1')}</h1></LayoutTitle>
      <Alert  variant="dark" style={{textAlign: 'center'}}>
        <h5>{t('more-lessons.h5')}</h5>
      </Alert> 
      {/* Video */}
      <div className="container-iframe">
        <iframe
          style={{ display: 'block', padding: '1rem 0', margin: '0 auto'}}
          width="90%"
          height="600"
          title={t("more-lessons.videoTitle")}
          src={t("more-lessons.videoUrl")}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen>
        </iframe>
      </div>

      <Alert  variant="dark" style={{textAlign: 'center'}}>
        <p>{t('more-lessons.link')}</p> <span role="img" aria-label="hand">👉</span>
       <Alert.Link href={t("main.link_telegram_chat")} target="blank">{t("main.here")}</Alert.Link>
      </Alert> 
    </div>
  )
}
export default IndexPage